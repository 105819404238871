@import 'sanitize.css';

$beige: #edddc5;
$black: #2f3034;
$blue: #2583fe;
$coral: #f1b76a;
$deepblue: #091e42;
$gold: #efb670;
$green: #9d8c78;
$grey: #2f3034;
$lightgrey: #c0c0c0;
$navyblue: #304463;
$sky: #b3d4ff;
$stone: #efeee8;
$white: #ffffff;

$unit: 4px;

$containerwidth: $unit * 285;

$neue: 'neue-haas-grotesk-display';

* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: Assistant;
}

#site-header {
  align-items: center;
  background-color: $white;
  display: flex;
  height: $unit * 14;
  justify-content: space-between;
  position: fixed;
  z-index: 9000;

  &.headroom {
    will-change: transform;
    transition: transform 200ms linear;
  }
  &.headroom--pinned {
    transform: translateY(0%);
  }
  &.headroom--unpinned {
    transform: translateY(-100%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
  font-family: $neue;
}

h1 {
  font-weight: bold;
  font-size: $unit * 11;
}

h2 {
  font-size: $unit * 7;
}

p {
  font-size: $unit * 6;
  line-height: 1.5em;
  font-weight: 300;
  opacity: 0.9;
}

strong {
  font-weight: 700;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

nav {
  display: flex;
  align-items: center;
}

nav a {
  font-weight: bold;
  margin-right: $unit * 10;
  color: $black;
}

section,
.container {
  width: 100%;
  padding: 0 calc(50vw - #{$containerwidth} / 2);
  overflow: hidden;
}

.button {
  background-color: $blue;
  border-radius: $unit;
  color: $white;
  font-family: $neue;
  font-weight: bold;
  height: $unit * 10;
  line-height: $unit * 10;
  text-align: center;
  text-decoration: none;
  width: $unit * 40;
}

.big-button {
  @extend .button;
  font-size: $unit * 5;
  height: $unit * 15;
  line-height: $unit * 15;
  width: $unit * 50;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  display: block;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.flip-x {
  transform: scaleX(-1);
}

.hero-content {
  background-color: $beige;
  background-image: url(../../images/white-mask.svg);
  background-position: 0 110%;
  background-size: contain;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20vh;
  padding-top: $unit * 40;

  h1 {
    font-size: $unit * 12;
  }

  div {
    width: $unit * 125;
  }

  &.no-mask {
    background-image: none;
    padding-bottom: 0;
  }
}

.footprints-container {
  background-image: url(../../images/footprints.svg);
  background-position: center;
  background-size: cover;
  color: $deepblue;

  &.bottom-mask {
    background-image: url(../../images/footprints.svg), url(../../images/footprints-mask.svg),
      linear-gradient(to bottom, $white 20%, $beige 20%);
    background-position: center ($unit * -50), bottom, top;
    background-size: cover, cover, cover;
    min-height: $unit * 200 + 1;
  }
}

.rocks-container {
  background-color: $beige;
  background-image: url(../../images/rocks.svg);
  background-position: center;
  background-size: contain;
  min-height: $unit * 200;
  padding-bottom: $unit * 20;
  padding-top: $unit * 50;

  &.bottom-mask {
    background-image: url(../../images/rocks.svg), url(../../images/white-mask.svg);
    background-position: center, bottom;
    background-size: contain, contain;
    min-height: $unit * 400;
    padding-bottom: $unit * 15;
    padding-top: $unit * 15;
  }
}

.stretched-background-container {
  color: $white;
  overflow: hidden;
  padding-bottom: $unit * 50;
  padding-top: $unit * 50;
  position: relative;
  z-index: 1;

  .mask {
    height: 100%;
    left: 0;
    object-fit: fill;
    position: absolute;
    top: 0;
    width: 105vw;
    z-index: -1;
  }

  *:not(.mask) {
    z-index: 2;
  }
}

.centered-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  width: $unit * 185;

  h1 {
    margin-top: $unit * 10;
  }

  strong,
  button,
  a {
    display: block;
    margin-bottom: $unit * 10;
    margin-top: $unit * 10;
  }

  hr {
    background-color: $deepblue;
    border: 0;
    height: 5px;
    margin-top: $unit * 10;
    width: $unit * 35;
  }
}

.grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: $unit * 10;
  margin-top: $unit * 10;
}

#book-a-call {
  background-color: $stone;
  border-top: $unit * 25 solid $white;
  padding-top: $unit * 10;
}

#site-footer {
  border-bottom: $unit * 5 solid $beige;
  padding-bottom: $unit * 30;
  padding-top: $unit * 30;

  nav {
    align-items: flex-end;
    border-bottom: 1px solid $stone;
    margin-bottom: $unit * 4;
    padding-bottom: $unit * 4;
  }

  p {
    color: $lightgrey;
    display: flex;
    font-size: $unit * 4;
    justify-content: space-between;
  }

  small {
    max-width: $unit * 135;
  }
}

@media only screen and (max-width: #{$containerwidth}) {
  @import './mobile.scss';
}
