@import '../global/index.scss';

#section-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .card-container {
    align-items: stretch;
    display: flex;
    margin-top: $unit * 40;
  }

  h2 {
    margin-top: $unit * 10;
    margin-bottom: $unit * 5;
  }

  .card {
    align-items: center;
    background-color: $stone;
    border-radius: $unit;
    color: $black;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $unit * 10;

    p {
      font-size: $unit * 5;
    }
  }

  .card + .card {
    margin-left: $unit * 4;
  }
}

#section-4 {
  align-items: center;
  background-image: linear-gradient(to bottom, $beige 50%, $white 50%);
  background-size: cover;
  display: flex;
  justify-content: space-between;

  h2 {
    color: $gold;
    font-size: $unit * 4;
    font-weight: 800;
    letter-spacing: 0.32px;
    margin: $unit * 4 0;
    text-transform: uppercase;
  }

  div {
    width: $unit * 150;
  }

  .illustration {
    position: absolute;
    bottom: 0;
    right: calc(50vw - #{$containerwidth} / 2);
  }
}

@media only screen and (max-width: #{$containerwidth}) {
  @import './mobile.scss';
}
