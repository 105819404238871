#site-header {
  display: flex;
  padding: 10px;
}

.button:not(.big-button) {
  padding: 0 $unit * 3;
  width: auto;
  font-size: 14px;
}

nav {
  display: flex;

  a.mobile-hide {
    display: none;
  }

  > a {
    margin-right: $unit * 4 !important;
    font-size: 14px;
  }

  img {
    width: $unit * 14;
    height: unset;
  }
}

h1 {
  font-size: $unit * 10;
}

p {
  font-size: $unit * 5;
}

section,
.container {
  overflow: hidden;
  padding: 0 $unit * 6;
  width: 100vw;
}

.hero-content {
  align-items: stretch;
  background-position: 0 101%;
  flex-direction: column;
  padding: $unit * 25 $unit * 6;

  h1 {
    font-size: $unit * 11;
  }

  img {
    object-fit: contain;
    height: 400px;
  }

  > * {
    width: auto !important;
    text-align: center;
  }

  > * + * {
    margin-top: $unit * 10;
  }
}

.footprints-container.bottom-mask {
  padding-bottom: $unit * 40;
}

.centered-content {
  width: 100%;

  h1 {
    margin-top: 0;
  }
}

.rocks-container {
  min-height: unset;
  padding-bottom: $unit * 10;
  padding-top: $unit * 10;

  &.bottom-mask {
    padding-bottom: 0;
    background-position: center, bottom -1px center;
  }
}

.stretched-background-container {
  padding-bottom: $unit * 40;
  padding-top: $unit * 40;

  .mask {
    width: 300vw;
  }
}

.grid {
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: $unit * 10;
  }
}

#book-a-call {
  border-top-width: $unit * 6;
}

#site-footer {
  padding-top: $unit * 10;
  padding-bottom: $unit * 10;

  nav {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: $unit * 8;
    margin-bottom: $unit * 8;

    > * + * {
      margin-top: $unit * 6;
    }
  }

  p {
    flex-direction: column;
  }
}
